export const initFancy = function() {
  Fancybox.bind('[data-fancybox]', {
    dragToClose: false
  });

  const fancyLink = document.querySelectorAll('[data-fancybox]');

  if (fancyLink.length > 0) {
    fancyLink.forEach((item) => {
      item.addEventListener('click', (i) => {
        const fancyContainer = document.querySelector('.fancybox__container');
        if (fancyContainer) {
          Fancybox.close();
        }
      });
    });
  }

  function fancyEvent(id) {
    new Fancybox([
      {
        src: id,
        type: 'inline'
      }
    ]);
  }

  document.addEventListener('wpcf7mailsent', (event) => {
    Fancybox.close();
    const openSussets = document.querySelector('[data-message-success]');
    if (openSussets) {
      fancyEvent(openSussets);
    }
  }, false);

  document.addEventListener('wpcf7mailfailed', (event) => {
    Fancybox.close();
    const openError = document.querySelector('[data-message-error]');
    if (openError) {
      fancyEvent(openError);
    }
  }, false);
};
