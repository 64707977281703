// import { Form } from './modules/form-validate/form';
import { iosVhFix } from './utils/ios-vh-fix';
import { initSliders } from './modules/slider/init-slider.js';
import { initAnimate } from './modules/animate/animate.js';
import { initBurger } from './modules/header/init-burger.js';
import { initFancy } from './modules/initFancy.js';
// import { initMaskPhone } from './modules/init-mask-phone.js';
// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  iosVhFix();

  window.addEventListener('load', () => {
    initBurger();
    // initMaskPhone();
    // const form = new Form();
    // window.form = form;
    // form.init();
    initFancy();
    initSliders();
    initAnimate();
  });
});
